<!-- eslint-disable no-undef -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${pos === 0 ? $t('lbl.addTarifa') : $t('lbl.deleteTarifa')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-contrate-update' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="pos === 0"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                :disabled="verifyItems"
                @click="save()"
              >
                <span>{{ $t('btn.add') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
              <v-btn
                v-else-if="pos === 1"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                :disabled="verifyDelete"
                @click="edit()"
              >
                <span>{{ $t('btn.edit') }}</span>
                <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
              </v-btn>
              <v-btn
                v-else-if="pos === 2"
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                :disabled="verifyDelete"
                @click="isDialogVisibleDelete = true"
              >
                <span>{{ $t('btn.delete') }}</span>
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row v-if="isLoading">
              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>

            <template v-else>
              <v-row>
                <v-col
                  v-if="model.proveedor_id"
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.identificador') }}</strong>: {{ model.identificador }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-tabs
                    v-model="pos"
                    icons-and-text
                  >
                    <v-tab>
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-tab>
                    <v-tab>
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-tab>
                    <v-tab>
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="pos">
                    <v-tab-item>
                      <v-row class="mt-5">
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-select
                            v-if="!loadingCategories"
                            v-model="tarifa.category_id"
                            :items="categoriesTarifarios"
                            :label="$t('menu.categoriesTarifarios')"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            item-text="name"
                            item-value="id"
                            @change="changeCategory"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  {{ $t('menu.categoriesTarifarios') }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                          <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            type="card-heading"
                          ></v-skeleton-loader>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-text-field
                            v-if="!loadingCategories"
                            v-model="tarifa.name"
                            hide-details
                            :label="$t('lbl.name')"
                            class="my-input mr-2"
                            outlined
                            dense
                          ></v-text-field>
                          <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            type="card-heading"
                          ></v-skeleton-loader>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-if="!loadingCategories"
                            v-model="tarifa.description"
                            hide-details
                            :label="$t('lbl.description')"
                            class="my-input mr-2"
                            outlined
                            dense
                          ></v-text-field>
                          <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            type="card-heading"
                          ></v-skeleton-loader>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-switch
                            v-if="!loadingCategories"
                            v-model="tarifa.no_reembolsable"
                            :label="$t('lbl.notReembolsable')"
                            hide-details
                          ></v-switch>
                          <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            type="card-heading"
                          ></v-skeleton-loader>
                        </v-col>
                        <v-col
                          v-if="!tarifa.no_reembolsable && !loadingCategories"
                          cols="12"
                          md="3"
                        >
                          <v-switch
                            v-model="tarifa.cancele_free"
                            :label="$t('lbl.canceleFree')"
                            hide-details
                          ></v-switch>
                        </v-col>
                        <v-col
                          v-if="!tarifa.no_reembolsable && tarifa.cancele_free"
                          cols="12"
                          md="3"
                        >
                          <v-switch
                            v-model="tarifa.cancele_free_all_time"
                            :label="$t('lbl.allTime')"
                            hide-details
                            @change="changeCanceleFreeAllTime()"
                          ></v-switch>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-btn
                            class="ma-2 cols-2"
                            outlined
                            color="indigo"
                            @click="showConditions()"
                          >
                            {{ $t('menu.conditionGenerals') }}
                          </v-btn>
                        </v-col>
                        <v-col
                          v-if="!tarifa.no_reembolsable && tarifa.cancele_free"
                          cols="12"
                          md="11"
                        >
                          <DataCancelacionFree
                            v-for="(data, index) in tarifa.cancele_free_data"
                            :key="index"
                            :pos="index"
                            :data="data"
                            :tarifa="tarifa"
                            @deleteTarifa="deleteTarifa"
                          />
                        </v-col>
                        <v-col
                          v-if="!tarifa.no_reembolsable && tarifa.cancele_free_data.length > 0 && tarifa.cancele_free"
                          cols="12"
                          md="1"
                        >
                          <v-tooltip
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mt-8"
                                fab
                                dark
                                x-small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                @click="addTarifa()"
                              >
                                <v-icon small>
                                  {{ icons.mdiPlus }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('btn.insert') }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item>
                      <v-row class="mt-5">
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-select
                            v-if="!isLoading"
                            v-model="tarifa_code"
                            :items="tarifas"
                            :label="$t('lbl.tarifa')"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            item-text="name"
                            item-value="code"
                            @change="changeTarifa"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  {{ $t('lbl.tarifa') }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>{{ item.name }} (<b>{{ item.code }}</b>)</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                          <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            type="card-heading"
                          ></v-skeleton-loader>
                        </v-col>

                        <template
                          v-if="tarifa.code"
                        >
                          <v-col
                            cols="12"
                            md="2"
                          >
                            <v-select
                              v-if="!loadingCategories"
                              v-model="tarifa.category_id"
                              :items="categoriesTarifarios"
                              :label="$t('menu.categoriesTarifarios')"
                              class="my-input mr-2"
                              outlined
                              dense
                              hide-details
                              item-text="name"
                              item-value="id"
                              @change="changeCategory"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title>
                                    {{ $t('menu.categoriesTarifarios') }}
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-select>
                            <v-skeleton-loader
                              v-else
                              class="mx-auto"
                              type="card-heading"
                            ></v-skeleton-loader>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <v-text-field
                              v-if="!loadingCategories"
                              v-model="tarifa.name"
                              hide-details
                              :label="$t('lbl.name')"
                              class="my-input mr-2"
                              outlined
                              dense
                            ></v-text-field>
                            <v-skeleton-loader
                              v-else
                              class="mx-auto"
                              type="card-heading"
                            ></v-skeleton-loader>
                          </v-col>
                          <v-col
                            cols="12"
                            md="5"
                          >
                            <v-text-field
                              v-if="!loadingCategories"
                              v-model="tarifa.description"
                              hide-details
                              :label="$t('lbl.description')"
                              class="my-input mr-2"
                              outlined
                              dense
                            ></v-text-field>
                            <v-skeleton-loader
                              v-else
                              class="mx-auto"
                              type="card-heading"
                            ></v-skeleton-loader>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <v-switch
                              v-if="!loadingCategories"
                              v-model="tarifa.no_reembolsable"
                              :label="$t('lbl.notReembolsable')"
                              hide-details
                            ></v-switch>
                            <v-skeleton-loader
                              v-else
                              class="mx-auto"
                              type="card-heading"
                            ></v-skeleton-loader>
                          </v-col>
                          <template v-if="!tarifa.no_reembolsable">
                            <v-col
                              v-if="!loadingCategories"
                              cols="12"
                              md="3"
                            >
                              <v-switch
                                v-model="tarifa.cancele_free"
                                :label="$t('lbl.canceleFree')"
                                hide-details
                              ></v-switch>
                            </v-col>
                            <v-col
                              v-if="tarifa.cancele_free"
                              cols="12"
                              md="3"
                            >
                              <v-switch
                                v-model="tarifa.cancele_free_all_time"
                                :label="$t('lbl.allTime')"
                                hide-details
                                @change="changeCanceleFreeAllTime()"
                              ></v-switch>
                            </v-col>
                          </template>
                          <v-col
                            cols="12"
                          >
                            <v-btn
                              class="ma-2 cols-2"
                              outlined
                              color="indigo"
                              @click="showConditions()"
                            >
                              {{ $t('menu.conditionGenerals') }}
                            </v-btn>
                          </v-col>
                          <template v-if="!tarifa.no_reembolsable">
                            <v-col
                              v-if="tarifa.cancele_free"
                              cols="12"
                              md="11"
                            >
                              <DataCancelacionFree
                                v-for="(data, index) in tarifa.cancele_free_data"
                                :key="index"
                                :pos="index"
                                :data="data"
                                :tarifa="tarifa"
                                @deleteTarifa="deleteTarifa"
                              />
                            </v-col>
                            <v-col
                              v-if="tarifa.cancele_free_data.length > 0 && tarifa.cancele_free"
                              cols="12"
                              md="1"
                            >
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="mt-8"
                                    fab
                                    dark
                                    x-small
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="addTarifa()"
                                  >
                                    <v-icon small>
                                      {{ icons.mdiPlus }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('btn.insert') }}</span>
                              </v-tooltip>
                            </v-col>
                          </template>
                        </template>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item>
                      <v-row class="mt-5">
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-select
                            v-if="!isLoading"
                            v-model="tarifa.code"
                            :items="tarifas"
                            :label="$t('lbl.tarifa')"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            item-text="name"
                            item-value="code"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  {{ $t('lbl.tarifa') }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>{{ item.name }} (<b>{{ item.code }}</b>)</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                          <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            type="card-heading"
                          ></v-skeleton-loader>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </template>
          </v-form>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <template v-if="!isLoadingHotels">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-uppercase"
                          width="5%"
                          rowspan="2"
                        >
                          <v-checkbox
                            v-model="checkAll"
                            hide-details
                            class="my-0"
                            @change="changeCheckAll"
                          ></v-checkbox>
                        </th>
                        <th
                          class="text-uppercase"
                          width="40%"
                          rowspan="2"
                        >
                          {{ $t('lbl.hotel') }}
                        </th>
                        <th
                          class="text-uppercase"
                          width="5%"
                          rowspan="2"
                        >
                          Code
                        </th>
                        <th
                          class="text-uppercase"
                          rowspan="2"
                        >
                          {{ $t('lbl.destino') }}
                        </th>
                        <th
                          class="text-uppercase text-center"
                          colspan="3"
                        >
                          {{ $t('lbl.tarifa') }}
                        </th>
                      </tr>
                      <tr>
                        <th class="text-uppercase">
                          {{ $t('lbl.name') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.code') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.slug') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <fragment
                        v-for="(iten, indP) in hotels"
                        :key="indP"
                      >
                        <tr v-if="iten.id === iten.id">
                          <td>
                            <v-checkbox
                              v-model="iten.check"
                              hide-details
                              class="my-0"
                              @change="changeCheckItem"
                            ></v-checkbox>
                          </td>
                          <td>
                            <NameHotelStar :hotel="iten" />
                          </td>
                          <td>{{ iten.code_hotel_externo }}</td>
                          <td>
                            {{ `${iten.city ? `${iten.city}, ` : ''}` }}
                            <span v-if="iten.country">{{ iten.country }}</span>
                          </td>
                          <td>
                            <span
                              v-for="(season, ind) in seasons"
                              :key="ind"
                            >
                              <span v-if="season.hotels.includes(iten.id)">
                                <span v-if="season.seasons">
                                  <span v-if="season.seasons.tarifas">
                                    <span
                                      v-for="(tarifa, indT) in season.seasons.tarifas"
                                      :key="indT"
                                    >
                                      {{ tarifa.name }}
                                      <br />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </td>
                          <td>
                            <span
                              v-for="(season, ind) in seasons"
                              :key="ind"
                            >
                              <span v-if="season.hotels.includes(iten.id)">
                                <span v-if="season.seasons">
                                  <span v-if="season.seasons.tarifas">
                                    <span
                                      v-for="(tarifa, indT) in season.seasons.tarifas"
                                      :key="indT"
                                    >
                                      {{ tarifa.code }}
                                      <br />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </td>
                          <td>
                            <span
                              v-for="(season, ind) in seasons"
                              :key="ind"
                            >
                              <span v-if="season.hotels.includes(iten.id)">
                                <span v-if="season.seasons">
                                  <span v-if="season.seasons.tarifas">
                                    <span
                                      v-for="(tarifa, indT) in season.seasons.tarifas"
                                      :key="indT"
                                    >
                                      <i>
                                        {{ tarifa.category_slug === 'solo-alojamiento' ? 'alojamiento' : tarifa.category_slug }}</i>
                                      <br />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </td>
                        </tr>
                      </fragment>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
              <v-skeleton-loader
                v-else
                class="mx-auto"
                type="table-tbody"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </app-card-code>
    </v-col>

    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          ¿Está seguro que desea eliminar la tarifa seleccionada?
          <br />
          <br />
          Esta acción es irreversible y dejaría a los hoteles sin precios de esta tarifa.
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SidebarConditions
      v-if="isSidebarConditions"
      v-model="isSidebarConditions"
      :tarifa="tarifa"
      :categories="categories"
    />
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus, mdiArrowLeft, mdiSquareEditOutline, mdiDeleteOutline, mdiUpdate,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import NameHotelStar from '@/views/products/hotels/utils/NameHotelStar.vue'
import { v4 as uuidv4 } from 'uuid'
import DataCancelacionFree from '../utils/DataCancelacionFree.vue'
import SidebarConditions from '../utils/SidebarConditions.vue'

export default {
  components: {
    AppCardCode,
    NameHotelStar,
    DataCancelacionFree,
    SidebarConditions,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      pos: 0,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
      },
      tarifa_code: null,
      tarifa: {
        code: '',
        name: '',
        category_id: 0,
        category_slug: '',
        description: '',
        conditions: [],
        no_reembolsable: false,
        cancele_free: false,
        cancele_free_all_time: false,
        cancele_free_data: [{
          range_date: false,
          dates: [{
            from: null,
            to: null,
          }],
          antes_de: true,
          param: 'hrs',
          from: 72,
          to: null,
          all_nights: false,
          cant_nigths: 1,
          all_rooms: true,
          cant_rooms: 1,
          penalidad_in_pc: true,
          penalidad_valor: 100,
        }],
      },
      loading: false,
      loadingCategories: true,
      categoriesTarifarios: [],
      hotels: [],
      hotelsNomAll: [],
      isLoadingHotels: true,
      hotels_pactados: [],
      checkAll: true,
      model: null,
      seasons: [],
      seasonByCode: [],
      tarifas: [],
      tarifasCodes: [],
      isDialogVisibleDelete: false,
      isSidebarConditions: false,
      categories: [],
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      observationsHotel: state => state.app.observationsHotel,
      idsHotelsAsociados: state => state.app.idsHotelsAsociados,
    }),
    verifyItems() {
      let result = false
      if (!this.tarifa.name || !this.tarifa.category_id) {
        result = true
      }
      let cantCheck = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cantCheck += 1
        }
      }
      if (cantCheck === 0) {
        result = true
      }

      if (!this.tarifa.no_reembolsable
            && !this.tarifa.cancele_free_all_time) {
        this.tarifa.cancele_free_data.forEach(element => {
          if (!element.penalidad_valor) {
            result = true
          }
        })
      }

      return result
    },
    verifyDelete() {
      let result = false
      if (!this.tarifa.code) {
        result = true
      }
      let cantCheck = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cantCheck += 1
        }
      }
      if (cantCheck === 0) {
        result = true
      }

      return result
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },

  created() {
    this.getItem()
    this.getCategoriesTarifarios()
  },

  methods: {
    ...mapMutations([
      'addObservationContrateHotel',
      'updateObservationContrateHotel',
      'updateIdsHotelsAsociadosContrate',
    ]),
    showConditions() {
      this.isSidebarConditions = true
    },
    changeCanceleFreeAllTime() {
      if (this.tarifa.cancele_free && !this.tarifa.cancele_free_all_time) {
        this.tarifa.cancele_free_data.push({
          antes_de: true,
          param: 'hrs',
          from: 72,
          to: null,
          all_nights: false,
          cant_nigths: 1,
          all_rooms: false,
          cant_rooms: 1,
          penalidad_in_pc: true,
          penalidad_valor: 100,
        })
      } else {
        this.tarifa.cancele_free_data = []
      }
    },
    addTarifa() {
      this.tarifa.cancele_free_data.push({
        antes_de: true,
        param: 'hrs',
        from: 72,
        to: null,
        all_nights: false,
        cant_nigths: 1,
        all_rooms: false,
        cant_rooms: 1,
        penalidad_in_pc: true,
        penalidad_valor: 100,
      })
    },
    deleteTarifa(pos) {
      this.tarifa.cancele_free_data.splice(pos, 1)
    },
    changeTarifa() {
      if (this.tarifa_code) {
        // eslint-disable-next-line prefer-destructuring
        this.tarifa = this.tarifas.filter(e => e.code === this.tarifa_code)[0]
      }
    },
    getItem() {
      if (localStorage.getItem('hotels-contrate-id') !== null) {
        const id = localStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.seasons = this.model.seasons
            this.hotels_pactados = this.model.hotels_pactados

            // LLENAR LAS TARIFAS
            this.seasons.forEach(elementSea => {
              if (elementSea.seasons) {
                if (elementSea.seasons.tarifas) {
                  elementSea.seasons.tarifas.forEach(elementTar => {
                    if (!this.tarifasCodes.includes(elementTar.code)) {
                      this.tarifasCodes.push(elementTar.code)
                      this.tarifas.push(elementTar)

                      /* this.tarifas.push({
                        name: elementTar.name,
                        code: elementTar.code,
                        category_slug: elementTar.category_slug,
                        category_id: elementTar.category_id,
                      }) */
                    }
                  })
                }
              }

              this.seasonByCode.push({
                code_hotel_externo: elementSea.code_hotel_externo,
                hotels: elementSea.hotels[0],
                publico: elementSea.publico,
              })
            })
          })
          .finally(() => {
            this.isLoading = false
            this.getCategoriesConditions()
            this.getHoteles()
          })
      } else {
        this.updateIdsHotelsAsociadosContrate([])
        this.updateObservationContrateHotel([])
        this.isLoading = false
        this.isLoadingSuplementos = false
      }
    },
    getCategoriesTarifarios() {
      this.axios
        .get(
          'nom_categories_tarifarios_hotels?per_page=10000',
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.categoriesTarifarios = res.data.data
          }
        })
        .finally(() => {
          this.loadingCategories = false
        })
    },
    changeCategory() {
      if (this.tarifa.category_id) {
        this.tarifa.category_slug = this.categoriesTarifarios.filter(e => e.id === this.tarifa.category_id)[0].slug
      }
    },
    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
        fields: [
          'id',
          'name',
          'slug',
          'operador_id',
          'tipo_destino_id',
          'direccion',
        ],
      }
      this.axios
        .post('hotels/faster', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotelsNomAll = res.data.data
            this.hotelsNomAll.forEach(element => {
              if (this.hotels_pactados.includes(element.id)) {
                let publico = false
                // eslint-disable-next-line camelcase
                let code_hotel_externo = null
                this.seasonByCode.forEach(seasonByC => {
                  if (seasonByC.hotels === element.id) {
                    publico = seasonByC.publico
                    // eslint-disable-next-line camelcase
                    code_hotel_externo = seasonByC.code_hotel_externo
                  }
                })

                this.hotels.push({
                  ...element,
                  check: true,
                  publico,
                  code_hotel_externo,
                })
              }
            })
          }
        })
        .finally(() => {
          this.isLoadingHotels = false
        })
    },
    changeCheckAll() {
      if (this.checkAll) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.hotels.length; index++) {
          this.hotels[index].check = true
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.hotels.length; index++) {
          this.hotels[index].check = false
        }
      }
    },
    changeCheckItem() {
      const cantH = this.hotels.length
      let cont = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cont += 1
        }
      }
      if (cont === cantH) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
    },
    getCategoriesConditions() {
      const json = {
        for_hotel: true,
        proveedor_id: this.model.proveedor_id,
      }
      this.axios
        .post('nom_categories_conditions/with-conditions', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categories = res.data.data.items
          }
        })
    },
    save() {
      const hot = []
      this.hotels.forEach(element => {
        if (element.check) {
          hot.push(element.id)
        }
      })
      this.tarifa.code = uuidv4().toUpperCase().substring(28)
      const json = {
        contrate_id: localStorage.getItem('hotels-contrate-id'),
        hotels: hot,
        tarifa: this.tarifa,
      }

      this.loading = true
      this.axios
        .post('contrate_hotels/agregar-tarifa-global', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            if (response.data.data.hotelsRepetido !== '') {
              this.$toast.info(this.$t('msg.hotelsWithTarifa', { n: response.data.data.hotelsRepetido }))
            }
            this.$toast.success(this.$t('msg.infoSuccess'))

            this.$router.push({ name: 'hotels-contrate-update' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    edit() {
      const hot = []
      this.hotels.forEach(element => {
        if (element.check) {
          hot.push(element.id)
        }
      })

      const json = {
        contrate_id: localStorage.getItem('hotels-contrate-id'),
        hotels: hot,
        tarifa: this.tarifa,
      }

      this.loading = true
      this.axios
        .post('contrate_hotels/edit-tarifa-global', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.$router.push({ name: 'hotels-contrate-update' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    confirmDelete() {
      const hot = []
      this.hotels.forEach(element => {
        if (element.check) {
          hot.push(element.id)
        }
      })
      this.tarifa.hotels = hot
      this.tarifa.contrate_id = localStorage.getItem('hotels-contrate-id')

      this.loading = true
      this.axios
        .post('contrate_hotels/delete-tarifa-global', this.tarifa, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.$router.push({ name: 'hotels-contrate-update' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isDialogVisibleDelete = false
          this.loading = false
        })
    },
  },
}
</script>
